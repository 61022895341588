<template>
	<div class="page">
		<div class="help-bt" v-if="wx!='' || dy!=''">视频学习</div>
		<div class="help-img" v-if="wx!='' || dy!=''">
			<div class="help-img-wx">
				<img :src="wx" class="help-ewm" v-if="wx!=''">
				<div class="help-img-bt" v-if="wx!=''">微信扫描二维码</div>
				<img :src="dy" class="help-ewm" v-if="wx=='' && dy!=''">
				<div class="help-img-bt" v-if="wx=='' && dy!=''">抖音扫描二维码</div>
			</div>
			<div class="help-img-dy" v-if="wx!='' && dy!=''">
				<img :src="dy" class="help-ewm">
				<div class="help-img-bt">抖音扫描二维码</div>
			</div>
		</div>
		<div class="help-bt">图文教学</div>
		<div class="help-html" v-html="nr"></div>
	</div>
</template>

<style scoped>
.help-html{
	width: 100%;
	margin-top: 20PX;
}
.help-img-bt{
	width: 100%;
	color: #000000;
	font-size: 14PX;
	text-align: center;
	margin-top: 10PX;
}
.help-ewm{
	display: block;
	width: 100%;
}
.help-img-wx{
	height: 100%;
	width: 150PX;
	float: left;
}
.help-img-dy{
	height: 100%;
	width: 150PX;
	float: right;
}
.help-img{
	width: 100%;
	height: 180PX;
	margin-top: 20PX;
	margin-bottom: 40PX;
}
.help-bt{
	width: 100%;
	font-size: 16PX;
	color: #000000;
	font-weight: bold;
}
.page{
	width: 100%;
	height: 100vh;
	background: #e6e6e6;
	box-sizing: border-box;
	padding: 30PX 20PX 30PX 20PX;
	overflow-y: auto;
}
</style>

<script>
export default {
	name: 'help',
	data () {
		return {
			id:'',
			wx:'',
			dy:'',
			nr:''
		}
	},
	mounted () {
		let that = this;
		that.id = that.$route.params.id;
		this.$http('/gethelp?id='+that.id).then(function (res) {
			that.dy = res.data.data[0].dy;
			that.wx = res.data.data[0].wx;
			that.nr = res.data.data[0].nr;
			that.bt = res.data.data[0].bt;
		}).catch(function (error) {
			
		});
	},
	methods: {
		
	}
};
</script>
